<template>
  <div class="section-records">
    <h5>Family Records in {{ pinyin }}</h5>
    <div class="records-container">
      <record-category-block
        :item="item"
        v-for="item in displayItems"
        :key="item.id"
        :get-extra-query="getExtraQuery"
      ></record-category-block>
    </div>
  </div>
</template>

<script>
import RecordCategoryBlock from '@common/elements/layouts/RecordCategoryBlock.vue';
import {TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import orderBy from 'lodash/orderBy';

export default {
  props: {
    pinyin: String,
    placeId: Number,
    meta: Object,
  },
  computed: {
    displayItems() {
      return orderBy(this.$store.getters.searchCategoriesListState, ['is_highlighted', 'priority'], ['desc', 'asc'])
        .map(category => {
          let facet = this.meta.facets.category_id[category.id];
          if (facet && category.id !== sourceTypeCategories.CATEGORY_ZUPUS) {
            return {...category, persons_count: facet.count, books_count: null};
          }
          facet = this.meta.sourcesMeta.facets.category_id[category.id];
          return facet ? {...category, persons_count: null, books_count: facet.count} : null;
        })
        .filter(item => item && (item.persons_count || item.books_count));
    },
  },
  methods: {
    getExtraQuery(item) {
      if (!this.$store.getters.userIsSubscribedState) {
        return {};
      }
      if (item.persons_count) {
        return {auto_place_id: this.placeId, tab: TAB_ID_INDEXED_RECORDS};
      }
      return {auto_place_id: this.placeId, tab: TAB_ID_SOURCES};
    },
  },
  components: {RecordCategoryBlock},
  name: 'SectionRecords',
};
</script>

<style scoped lang="scss">
.section-records {
  .records-container {
    width: 100%;
    margin-top: 12px;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .records-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
