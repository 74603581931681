<template>
  <div class="search-overseas-form">
    <div class="filter">
      <label for="query">Search for a place</label>
      <input id="query" class="input" v-model="q" placeholder="e.g. San Francisco" @keyup.enter="onInputEnter" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      q: this.q,
    };
  },
  methods: {
    setFormData(data) {
      this.q = data.q;
    },
    getData() {
      return {q: this.q};
    },
    onInputEnter() {
      this.$emit('submit');
    },
  },
  name: 'SearchOverseasForm',
};
</script>

<style scoped lang="scss"></style>
