<template>
  <metadata-table class="place-address">
    <template v-slot:rows>
      <detail-item
        v-for="item in displayItems"
        v-prerender-for="{listName: 'ancestors', valueName: 'ancestor'}"
        :key="item.id"
        :label="getLabel(item)"
      >
        <router-link :to="getRoute(item)" rel="nofollow">{{ getDisplayName(item) }}</router-link>
      </detail-item>
      <detail-item label="Full Address (EN)" class="address-en">{{ fullAddressEn }}</detail-item>
      <detail-item label="Full Address (CN)" v-if="hasChineseAddress">{{ fullAddressCh }}</detail-item>
    </template>
  </metadata-table>
</template>

<script>
import MetadataTable from '@common/elements/layouts/MetadataTable';
import detailItem from '@common/elements/layouts/detailsItem';
import {isChineseText} from '@common/utils/utils';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    items: Array,
    fullAddressEn: String,
    fullAddressCh: String,
    isTemplate: {type: Boolean, default: false},
  },
  computed: {
    displayItems() {
      return [...this.items].sort((a, b) => a.level - b.level);
    },
    hasChineseAddress() {
      return this.isTemplate || (this.fullAddressCh && isChineseText(this.fullAddressCh));
    },
  },
  methods: {
    getLabel(item) {
      if (item.level_name) {
        return item.level_name;
      }
    },
    getRoute(item) {
      return getPlaceDetailRoute(item.id, item.pinyin);
    },
    getDisplayName(item) {
      return item.name === item.pinyin ? item.name : `${item.name} ${item.pinyin}`;
    },
  },
  components: {detailItem, MetadataTable},
};
</script>

<style lang="scss" scoped>
.place-address {
  margin-top: 16px;
}
</style>
