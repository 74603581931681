<template>
  <div class="readable_content" v-if="loading || !renderComponent">
    <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
  </div>
  <component v-else-if="renderComponent" :is="renderComponent"></component>
</template>

<script>
import {mapGetters} from 'vuex';

import MigrationDestinationDetailsPage from './migrationDestinationDetails/MigrationDestinationDetailsPage';
import VillageDetailsPage from './villageDetails/VillageDetailsPage';

const PageNotFound = () => import('@/components/page.not.found');

export default {
  created() {
    this.init();
  },
  watch: {
    '$route.params': {
      handler: function (toParams, fromParams) {
        if (toParams.placeId !== fromParams.placeId) {
          this.init();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['placeState', 'placeLoadingState']),
    loading() {
      return !this.loaded;
    },
    renderComponent() {
      if (!this.placeState || !this.placeState.id) {
        return PageNotFound;
      }
      if (this.placeState.is_ancestral_place) {
        return VillageDetailsPage;
      }
      return MigrationDestinationDetailsPage;
    },
  },
  methods: {
    init() {
      this.$scrollTo({x: 0, y: 0});

      this.$store.commit('setPlaceState', {});
      this.loaded = false;
      this.$store.dispatch('fetchPlaceDetailsAction', this.$route.params.placeId).finally(() => {
        this.loaded = true;
      });
    },
  },
  components: {MigrationDestinationDetailsPage, VillageDetailsPage, PageNotFound},
  name: 'PlaceDetailsPage',
};
</script>

<style scoped></style>
