<template>
  <stand-out-block-split class="call-to-action-block">
    <div slot="message" class="image-content"><img :src="image_url" /></div>
    <div slot="content">
      <div class="title call-to-action">{{ title }}</div>
      <mcr-button @click="goToRegister" :label="registerLabel"></mcr-button>
    </div>
  </stand-out-block-split>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {mapGetters} from 'vuex';

import StandOutBlockSplit from '@/base/elements/layouts/StandOutBlockSplit';

export default {
  props: {
    pinyin: String,
    surnamesToSave: Array,
  },
  data() {
    return {
      image_url: this.$getAsset('/assets/services/tree/family-tree-with-grey-bg-no-shadow.png'),
    };
  },
  computed: {
    ...mapGetters(['usersCountDisplayState']),
    title() {
      return `Discover your ${this.pinyin} ancestors. Start your family tree today.`;
    },
    registerLabel() {
      return 'Get Started for FREE';
    },
    toRegister() {
      return {name: 'register', query: {redirect: this.targetRouteString}};
    },
  },
  methods: {
    goToRegister() {
      AnalyticsMainHandler.trackSurnameGetStartedClick(this.registerLabel, this.$route.query.word);
      const getStartedPage = this.$router.resolve({name: 'familytree-details-my'}).href;
      this.$store.commit('setSurnamesRegisterState', this.surnamesToSave);
      this.$router.push({name: 'register', query: {redirect: getStartedPage}});
    },
  },
  components: {McrButton, StandOutBlockSplit},
};
</script>

<style lang="scss" scoped>
.call-to-action-block {
  &::v-deep .component_content.message {
    padding: 0;
    max-height: 400px;
    display: flex;
  }
  &::v-deep .component_content.content {
    padding: 40px;

    .mcr-button {
      font-weight: 800;
    }
  }

  .call-to-action {
    font-size: 22px;
    margin-bottom: 40px;
  }

  .image-content {
    display: flex;
    width: 100%;
    img {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
