export const LEVELS = {
  country: 0,
  province: 1,
  city: 2,
  district: 3,
  town: 4,
  village: 5,
  sub_village: 6,
};
export const QUERY_LEVELS = {
  country: 'country',
  province: 'province',
  city: 'city',
  district: 'district',
  town: 'town',
  village: 'village',
  sub_village: 'subvillage',
};

export const PLACE_SEARCH_QUERY_LEVELS = {
  /* not include country, so Taiwan is not shown as country */
  [QUERY_LEVELS.province]: LEVELS.province,
  [QUERY_LEVELS.city]: LEVELS.city,
  [QUERY_LEVELS.district]: LEVELS.district,
  [QUERY_LEVELS.town]: LEVELS.town,
  [QUERY_LEVELS.village]: LEVELS.village,
  [QUERY_LEVELS.sub_village]: LEVELS.sub_village,
};

export function getPlaceSearchQueryByLevel(level) {
  level = parseInt(level);
  return Object.keys(PLACE_SEARCH_QUERY_LEVELS).find(key => PLACE_SEARCH_QUERY_LEVELS[key] === level);
}
