<template>
  <section-related
    title="Dialect(s) Spoken: "
    :items="dialects"
    prerender-list-name="dialects_list"
    :is-template="isTemplate"
  >
    <template v-slot:item="{item}">
      <router-link :to="getDialectDetailRoute(item.slug)">{{ item.name }}</router-link>
    </template>
  </section-related>
</template>

<script>
import SectionRelated from '@common/elements/layouts/SectionRelated';
import {getDialectDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    dialects: Array,
    isTemplate: Boolean,
  },
  methods: {getDialectDetailRoute},
  components: {SectionRelated},
};
</script>

<style scoped></style>
