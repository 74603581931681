export default {
  meta: {
    total_count: '{{ descendants.meta.total_count }}',
  },
  objects: [
    {
      address_en: '{{ descendant.address_en }}',
      id: '{{ descendant.id }}',
      name: '{{ descendant.name }}',
    },
  ],
};
