<template>
  <div class="section-wiki-articles" v-show="articles.length">
    <span class="title">
      <strong>Related Wiki Articles</strong>
      <img :src="$getAsset('/assets/mcr-db-badge-logo.png')" height="19" width="60" />
    </span>
    <ul>
      <li v-for="article in articles">
        <router-link :to="getArticleRoute(article)">{{ article.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    placeId: Number,
    excludeSlug: String,
  },
  created() {
    const fields = 'is_category,title,slug';
    this.$store.dispatch('wikiSearchInBackgroundAction', {place_id: this.placeId, fields}).then(response => {
      this.articles = response.objects.filter(article => article.slug !== this.excludeSlug);
    });
  },
  data() {
    return {
      articles: [],
    };
  },
  methods: {
    getArticleRoute(article) {
      const slug = article.slug;
      return article.is_category ? {name: 'wiki-category', params: {slug}} : {name: 'wiki-article', params: {slug}};
    },
  },
};
</script>

<style lang="scss" scoped>
.section-wiki-articles {
  .title {
    display: flex;
    align-items: flex-start;
    img {
      margin-left: 7px;
      margin-top: 3px;
    }
  }

  ul {
    margin-top: 0;
  }
}
</style>
