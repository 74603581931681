<template>
  <div class="search-villages-form-container">
    <div class="filter">
      <label for="query">Search for an Ancestral Place</label>
      <input
        id="query"
        class="input"
        :value="placeSearchQueryState"
        :class="getClasses('query')"
        :placeholder="queryPlaceholder"
        @change="onQueryChange"
        @keyup.enter="onInputEnter"
      />
      <div class="error" v-if="errors.query">{{ errors.query }}</div>
    </div>

    <place-input
      class="filter"
      label="Search within"
      :value="placeSearchRootPlaceState"
      :show-default-options="true"
      :only-ancestral-places="true"
      :error="errors.place"
      multiselectClasses="bordered"
      @select="onPlaceSelect"
    ></place-input>

    <advanced-filters-toggle :init-expanded="initAdvancedFiltersActive">
      <surname-input
        class="filter"
        :show-default-label="true"
        :allow-spelling-select="true"
        :value="placeSearchClanState"
        :error="errors.clan"
        @select="onClanSelect"
      ></surname-input>

      <administrative-level-filter
        class="filter"
        :value="placeSearchLevelState"
        @select="onLevelSelect"
      ></administrative-level-filter>
    </advanced-filters-toggle>
    <div class="error" v-if="errors.non_field">{{ errors.non_field }}</div>
  </div>
</template>

<script>
import AdvancedFiltersToggle from '@common/elements/forms/AdvancedFiltersToggle';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import SurnameInput from '@common/elements/inputs/SurnameInput';
import {mapGetters} from 'vuex';

import AdministrativeLevelFilter from './AdministrativeLevelFilter';

export default {
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    const query = this.$route.query;
    return {
      initAdvancedFiltersActive: Boolean(query.surname || query.surname_name || query.level),
    };
  },
  computed: {
    ...mapGetters([
      'placeSearchQueryState',
      'placeSearchRootPlaceState',
      'placeSearchClanState',
      'placeSearchLevelState',
    ]),
    queryPlaceholder() {
      return 'e.g. "Guangdong"';
    },
  },
  methods: {
    getClasses(field) {
      return {'has-error': this.errors[field]};
    },
    onQueryChange(event) {
      this.$store.commit('setPlaceSearchQueryState', event.target.value.trim());
    },
    onPlaceSelect(value) {
      this.$store.commit('setPlaceSearchRootPlaceState', value);
    },
    onClanSelect(value) {
      this.$store.commit('setPlaceSearchClanState', value);
    },
    onLevelSelect(value) {
      this.$store.commit('setPlaceSearchLevelState', value);
    },
    onInputEnter() {
      this.$emit('submit');
    },
  },
  components: {SurnameInput, PlaceInput, AdministrativeLevelFilter, AdvancedFiltersToggle},
};
</script>

<style lang="scss" scoped>
.search-villages-form-container {
  .surname-input {
    margin-top: 12px;
  }
}
</style>
