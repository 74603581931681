<template>
  <stand-out-block class="section-search-place-records">
    <div class="bold">Search Overseas Chinese records in {{ placeName }}</div>
    <div class="form">
      <input placeholder="First name" v-model="firstName" @keyup.enter="go" class="input" />
      <input placeholder="Last name" v-model="lastName" @keyup.enter="go" class="input" />
      <mcr-button @click="go">Search</mcr-button>
    </div>
    <router-link :to="searchRoute" class="view-all-link">View all records in this place</router-link>
  </stand-out-block>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';

export default {
  props: {
    placeName: String,
    placeId: [Number, String],
  },
  data() {
    return {
      firstName: '',
      lastName: '',
    };
  },
  computed: {
    searchRouteQuery() {
      return {auto_place_id: this.placeId, tab: TAB_ID_INDEXED_RECORDS};
    },
    searchRoute() {
      return {name: 'search-all-records', query: this.searchRouteQuery};
    },
  },
  methods: {
    go() {
      const query = {...this.searchRouteQuery, first_name: this.firstName, surname: this.lastName};
      this.$router.push({name: 'search-all-records', query: query});
    },
  },
  name: 'SectionSearchPlaceRecords',
  components: {StandOutBlock, McrButton},
};
</script>

<style scoped lang="scss">
.section-search-place-records {
  .form {
    display: flex;
    align-items: stretch;
    margin-top: 15px;

    > input {
      flex-grow: 1;
      margin: 0 15px 0 0;
      min-width: 0;
    }
  }

  .view-all-link {
    display: block;
    margin-top: 18px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .form {
      flex-direction: column;

      > *,
      > input {
        margin: 10px 0;
        width: 100%;
      }
    }

    .view-all-link {
      margin-top: 8px;
    }
  }
}
</style>
