<template>
  <div class="stand-out-block-split">
    <div class="wrapper">
      <div class="component_content message">
        <slot name="message"></slot>
      </div>

      <div class="component_content content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandOutBlockSplit',
};
</script>

<style lang="scss" scoped>
.stand-out-block-split {
  display: flex;
  align-items: center;

  .wrapper {
    display: flex;
    flex-grow: 1;
    border-radius: 4px;
    background-color: $background-light;

    .content {
      flex-grow: 1;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .wrapper {
      flex-direction: column;

      .message {
        width: auto;
      }
    }
  }
}
</style>
