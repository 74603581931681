<template>
  <div class="advanced-search-toggle">
    <div class="divider upper toggler" :class="{clickable: !showSlot}" @click="expand">{{ title }}</div>
    <div class="wrapper" :class="{expanded: showSlot}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initExpanded: {type: Boolean, default: false},
  },
  data() {
    return {
      showSlot: this.initExpanded,
    };
  },
  computed: {
    title() {
      return this.showSlot ? 'Advanced Search' : 'Show Advanced Search';
    },
  },
  methods: {
    expand() {
      this.showSlot = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-search-toggle {
  .taggler {
    padding: 18px 0;
  }
  .toggler.clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .wrapper {
    transition: max-height 0.5s ease;
    max-height: 0;
    overflow: hidden;
    &.expanded {
      max-height: 1000px;
      overflow: visible;
    }
  }
}
</style>
