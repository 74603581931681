<template>
  <section-related title="Major Clans: " :items="clans" :is-template="isTemplate" prerender-list-name="clans">
    <router-link
      rel="nofollow"
      slot="item"
      slot-scope="{item}"
      :to="{name: 'search-tool-surname-detail', query: {word: item.name_hant || item.name_ch}}"
    >
      {{ item.name_hant || item.name_ch }} {{ item.pinyin }}</router-link
    >
  </section-related>
</template>

<script>
import SectionRelated from '@common/elements/layouts/SectionRelated';

export default {
  props: {
    clans: Array,
    isTemplate: Boolean,
  },
  components: {SectionRelated},
};
</script>

<style scoped></style>
