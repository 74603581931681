import { render, staticRenderFns } from "./SectionRecords.vue?vue&type=template&id=a6703714&scoped=true&"
import script from "./SectionRecords.vue?vue&type=script&lang=js&"
export * from "./SectionRecords.vue?vue&type=script&lang=js&"
import style0 from "./SectionRecords.vue?vue&type=style&index=0&id=a6703714&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6703714",
  null
  
)

export default component.exports