<template>
  <div class="search-result-item place-result-item">
    <h4>
      <router-link :to="toRoute" class="visitable"> {{ item.pinyin }}</router-link>
    </h4>

    <div class="supplemental alternative" v-if="alsoKnownAs" v-html="alsoKnownAs"></div>

    <div class="addresses">
      <div>{{ item.full_address_en }}</div>
    </div>
  </div>
</template>

<script>
import {getAliasMatchHighlighted} from '@common/utils/utils.names';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    item: Object,
  },
  computed: {
    toRoute() {
      return getPlaceDetailRoute(this.item.id, this.item.pinyin);
    },
    alsoKnownAsMatches() {
      if (!this.$route.query.q) {
        return;
      }
      const matches = getAliasMatchHighlighted(this.item.aliases, this.$route.query.q, [
        this.item.pinyin,
        this.item.name,
      ]);
      return matches.join(', ');
    },
    alsoKnownAs() {
      const matches = this.alsoKnownAsMatches;
      return matches ? `Also known as "${matches}"` : '';
    },
  },
  name: 'SearchOverseasResultItem',
};
</script>

<style scoped lang="scss"></style>
