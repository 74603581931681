<template>
  <div class="search-villages-results-empty results-empty">
    <h5>No match found.</h5>
    <div>Sorry! No match found using these search terms. Why not try:</div>
    <ul>
      <li>Using a different spelling</li>
      <li>Expanding your search area</li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
