<template>
  <div class="popular-surnames-section">
    <h5 v-if="spellingsFound">Popular Chinese Surnames in {{ pinyin }}</h5>
    <div v-if="spellingsFound">
      <div class="surnames-container" :class="{trimmed: !showAll}">
        <div v-for="spelling in spellingSearchState" v-prerender-for="{listName: 'spellings', valueName: 'spelling'}">
          <router-link :to="{name: 'search-tool-surname-detail', query: {word: spelling.name}}" rel="nofollow">{{
            spelling.name
          }}</router-link>
        </div>
      </div>
      <a v-if="hasMore && !showAll" class="divider" @click="showAllClick">View all</a>
    </div>
    <loading-animated v-if="spellingSearchLoadingState">
      <vcl-table
        :columns="loadingColumns"
        slot-scope="props"
        :primary="props.primary"
        :secondary="props.secondary"
      ></vcl-table>
    </loading-animated>

    <stand-out-block class="section" v-if="spellingsFound">
      <surname-search-form-block title="Jump directly to your surname"></surname-search-form-block>
    </stand-out-block>
  </div>
</template>

<script>
import SurnameSearchFormBlock from '@common/elements/forms/SurnameSearchFormBlock';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import {VclTable} from 'vue-content-loading';
import {mapGetters} from 'vuex';

export default {
  props: {
    pinyin: String,
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    ...mapGetters(['spellingSearchLoadingState', 'spellingSearchState', 'spellingSearchMetaState']),
    hasMore() {
      return Boolean(this.spellingSearchMetaState.next);
    },
    loadingColumns() {
      return this.isMobile ? 2 : 4;
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    },
    spellingsFound() {
      return this.spellingSearchState && this.spellingSearchState.length;
    },
  },
  methods: {
    showAllClick() {
      this.$store.dispatch('searchSpellingsAction', {place_id: this.$route.params.placeId, limit: 0}).then(() => {
        this.showAll = true;
      });
    },
  },
  components: {LoadingAnimated, VclTable, StandOutBlock, SurnameSearchFormBlock},
};
</script>

<style lang="scss" scoped>
.surnames-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px 100px;
  padding: 30px 0;

  @media only screen and (max-width: $breakpoint-mobile) {
    grid-template-columns: repeat(2, 1fr);
    &.trimmed {
      max-height: 210px;
      overflow: hidden;
    }
  }
}
.loading-animated {
  margin-top: 30px;
}
.section {
  margin-top: 50px;
}
</style>
