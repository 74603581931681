<template>
  <section-related
    title="Also Known As: "
    :items="aliases"
    prerender-list-name="aliases"
    :is-template="isTemplate"
  ></section-related>
</template>

<script>
import SectionRelated from '@common/elements/layouts/SectionRelated';

export default {
  props: {
    aliases: Array,
    isTemplate: Boolean,
  },
  components: {SectionRelated},
};
</script>

<style scoped></style>
